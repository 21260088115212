import type Vue from 'vue'
import { RouteConfig } from 'vue-router'

function generateRoutes (app: Vue) {
  const routes: RouteConfig[] = []
  if (app.$permission.can(null, 'learningpath-update')) {
    routes.push({
      path: '/learningpaths',
      name: 'base-management.learningpaths',
      component: () => import(/* webpackChunkName: "learningpaths-views" */'../views/LearningPathListView.vue')
    }, {
      path: '/learningpath',
      component: { render: (h: Function) => h('RouterView') },
      children: [{
        path: '/learningpath/create/:view?',
        name: 'base-management.learningpath.create',
        component: () => import(/* webpackChunkName: "learningpaths-views" */'../views/LearningPathEdit.vue'),
        props: true,
        meta: {
          breadcrumbs: {
            text: 'core.action.create',
            to: null
          }
        }
      }, {
        path: ':learningPathId/:view?/:viewId?',
        name: 'base-management.learningpath.edit',
        component: () => import(/* webpackChunkName: "learningpaths-views" */'../views/LearningPathEdit.vue'),
        props: true,
        meta: {
          breadcrumbs: {
            text: 'core.action.edit',
            to: null
          }
        }
      }]
    })
  }
  return routes
}

export {
  generateRoutes
}
